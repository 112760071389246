body {
    #app {
        .content-container {
            .welcome {
                .panel-body {
                    .slider {
                        display: none;

                        &.open {
                            display: block;
                        }
                    }

                    .bg-img {
                        display: none;
                    }

                    .bg-pic {
                        width: 250px;
                        height: 140px;
                        background: $white;
                        text-align: center;
                        display: inline-block;
                        cursor: pointer;
                        @include flex();
                        align-items: center;
                        justify-content: center;

                        &:hover {
                            img {
                                @include transition(all 0.3s ease);
                                @include transform(scale(2.5));
                            }    
                        }

                        img {
                            width: 35px;
                            @include transition(all 0.3s ease);
                        }
                    }

                    .partner-slider-block {
                        text-align: center;

                        .title {
                            text-align: left;
                        }
                        
                        .partner-slider {
                            width: 275px;
                            display: inline-block;
                            background: $white;
                            padding: 15px;
                            margin-right: 10px;
                            text-align: center;
                            vertical-align: top;

                            .partner-change {
                                width: 100%;
                            }

                            input {
                                border: 1px solid $light-gray !important;
                                @include border-radius(0);
                                text-align: center;
                            }

                            .bg-pic {
                                border: 1px solid $light-gray;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}