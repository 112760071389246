body {
    #app {
        .content-container {
            .photos {
                .panel {
                    .panel-body {
                        .item {
                            margin: 0 15px 22px 0;
                            display: inline-block;
                            vertical-align: top;
                            position: relative;

                            .comments {
                                position: absolute;
                                left: 58px;
                                bottom: -14px;
                                @include border-radius(100%);
                                width: 30px;
                                height: 30px;
                                background: $white;

                                button {
                                    background: url(/icons/comments.svg);
                                    background-color: transparent;
                                    border: 0;
                                    @include background-size(100%);
                                    width: 17px;
                                    height: 17px;
                                    margin-left: 6px;
                                    margin-top: 6px;
                                    @include transition(all 0.3s ease);
                                    outline: none;

                                    &:hover {
                                        @include transform(scale(1.1));
                                    }
                                }
                            }
                            
                            .content {
                                background: $white;
                                height: 35px;
                                vertical-align: middle;
                                display: flex;
                                align-items: center;
                                padding-left: 10px;
                                margin-bottom: 5px;
                                position: relative;
                            }

                            .photo-title {
                                margin-bottom: 5px;
                            }

                            .item-img {
                                width: 180px;
                                height: 180px;
                                background-color: $white;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                vertical-align: top;
                                position: relative;

                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }

                            .cross-button {
                                background: url(/images/trash.svg);
                                @include background-size(16px);
                                background-position-x: 7px;
                                background-position-y: 6px;
                                width: 30px;
                                height: 30px;
                                @include border-radius(100%);
                                background-repeat: no-repeat;
                                position: absolute;
                                bottom: -15px;
                                right: 55px;
                                 @include transition(all 0.3s ease);
                                outline: none;
                                background-color: $white;

                                &:hover {
                                    @include transform(scale(1.1));
                                }
                            }

                            .edit {
                                position: absolute;
                                bottom: 8px;
                                left: 75px;

                                img {
                                    width: 15px;
                                }
                            }
                        }

                        .new-photo {
                            width: 180px;
                            height: 180px;
                            border: 1px solid $blue;
                            display: flex;
                            justify-content: center;
                            cursor: pointer;
                            margin-top: 27px;

                            img {
                                width: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
}