body {
    .shop {
        .grid-item {
            .price {
                margin-top: 10px;
            }
            
            .image {
                .discount {
                    background: $blue;
                    width: 70px;
                    height: 70px;
                    color: $white;
                    border-radius: 100%;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right: 32px;
                    position: absolute;
                }
            }
        }
    }
}