body {
    #app {
        .content-container {
            .ad {
                .ad-preview {
                    display: none;
                }

                &.ad-list {
                    label {
                        font-weight: normal;
                    }

                    .filters {
                        position: absolute;
                        margin-top: 2px;
                        z-index: 1;

                        @media screen and (max-width: 640px) {
                            position: relative;
                        }

                        .personal-only {
                            display: inline-block;
                        }

                        .filter-title {
                            font-weight: bold;
                            margin-bottom: 5px;
                        }

                        .filter {
                            display: inline-block;
                            width: 250px;
                            background: $light-gray;
                            padding: 5px 10px 5px 10px;
                            position: relative;
                            cursor: pointer;
                            margin-bottom: 15px;

                            &::before {
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 5px solid $blue;
                                @include transition(all 0.3s ease);
                                content: "";
                                position: absolute;
                                right: 10px;
                                top: 15px;
                            }

                            &.active {
                                &::before {
                                    @include transform(rotate(180deg));
                                }
                            }
                        }

                        .checkboxes {
                            display: none;
                            position: absolute;
                            width: 250px;
                            background: $light-gray;
                            padding: 10px 10px 3px 10px;
                            top: 32px;
                            z-index: 1;
                            border-top: 1px solid $border-gray;
                        }
                    }

                    .dataTables_length {
                        display: none;
                    }
                }
                
                .ad_previews {
                    width: 100%;
                    text-align: center;
                }

                .small-preview {
                    background: url(/images/phone.png);
                    width: 310px;
                    height: 630px;
                    @include background-size(cover);
                    display: inline-block;
                    vertical-align: top;
                    margin-bottom: 30px;
                    position: relative;

                    img {
                        max-width: calc(100% - 42px);
                        max-height: calc(100% - 234px);
                        position: absolute;
                        left: calc(50% - 1px);
                        top: calc(50% + 4px);
                        transform: translate(-50%, -50%);
                    }

                    @media screen and (max-width: 650px) {
                        margin-right: 0;
                    }

                    div {
                        background: $orange;
                        width: 260px;
                        height: 77px;
                        text-align: center;
                        position: relative;
                        top: 270px;
                        margin-left: 24px;
                        color: $white;
                        font-size: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include background-size(100% !important);
                        background-repeat: no-repeat !important;
                        background-position: center center !important;
                    }
                }

                .full-preview {
                    background: url(/images/phone.png);
                    width: 310px;
                    height: 620px;
                    background-size: cover;
                    display: inline-block;
                    vertical-align: top;
                    margin-bottom: 30px;

                    div {
                        background: $orange;
                        width: 267px;
                        height: 394px;
                        text-align: center;
                        position: relative;
                        top: 121px;
                        margin-left: 21px;
                        color: $white;
                        font-size: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include background-size(cover !important);
                        background-repeat: no-repeat !important;
                        background-position: center center !important;
                    }
                }

                .row {
                    .panel {
                        .panel-body {
                            position: relative;

                            .cross-button {
                                background: url(/images/close.svg);
                                background-color: transparent;
                                width: 20px;
                                height: 20px;
                                background-repeat: no-repeat;
                                position: relative;
                                top: 3px;
                                @include transition(all 0.5s ease);

                                &:hover {
                                    @include transform(rotate(360deg));
                                }
                            }

                            #advertiser-block {
                                padding: 10px 15px 5px;
                                background: $light-gray;
                                margin-bottom: 20px;
                                display: none;

                                .title {
                                    margin: 4px 0 20px;
                                    padding: 0 0 10px;
                                }
                            }

                            .select-all {
                                width: 100% !important;
                                border-bottom: 1px solid $border-gray;
                                margin: 11px 0 7px;
                            }

                            .exact-athletes {
                                label {
                                    font-weight: normal;

                                    &:first-child {
                                        display: block;
                                        font-weight: bold;
                                    }
                                }

                                div {
                                    display: inline-block;
                                    width: 30%;
                                }
                            }

                            .exact-sports {
                                label {
                                    font-weight: normal;

                                    &:first-child {
                                        display: block;
                                        font-weight: bold;
                                    }
                                }

                                div {
                                    display: inline-block;
                                    width: 30%;
                                }
                            }

                            #new-ad {
                                width: 250px;
                                height: 140px;
                                background: $white;
                                text-align: center;
                                display: inline-block;
                                cursor: pointer;
                                @include flex();
                                align-items: center;
                                justify-content: center;
                                border: 1px solid $light-gray;

                                &:hover {
                                    img {
                                        @include transition(all 0.3s ease);
                                        @include transform(scale(2.5));
                                    }
                                }

                                img {
                                    width: 35px;
                                    @include transition(all 0.3s ease);
                                }
                            }

                            #advertiser {
                                .logo {
                                    width: 40px;
                                    height: 40px;
                                    @include background-size(cover !important);
                                    @include border-radius(100%);
                                    text-align: center;
                                    background-position: center center !important;
                                }
                            },

                            #ads {
                                #affads-table {
                                    margin-top: 60px;
                                }

                                display: block;

                                @media screen and (max-width: 700px) {
                                    overflow-x: auto;
                                    width: 100%;
                                }

                                .logo {
                                    width: 40px;
                                    height: 40px;
                                    @include background-size(cover !important);
                                    @include border-radius(100%);
                                    text-align: center;
                                    background-position: center center !important;
                                }

                                .content {
                                    position: relative;
                                    width: 250px;
                                    height: 142px;
                                    border: 1px solid $light-gray;
                                    cursor: pointer;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        background: $black;
                                        @include opacity(0);
                                        left: 0;
                                        @include transition(all 0.5s ease);
                                    }

                                    &::after {
                                        content: '';
                                        background: url(/icons/settings-white.svg);
                                        height: 66px;
                                        background-repeat: no-repeat;
                                        width: 50px;
                                        display: block;
                                        position: absolute;
                                        @include background-size(100%);
                                        @include opacity(0);
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        @include transition(all 0.5s ease);
                                    }

                                    &:hover::after {
                                        @include opacity(1);
                                    }

                                    &:hover::before {
                                        @include opacity(0.7);
                                    }

                                    .delete-ad {
                                        background: url(/images/close.png);
                                        position: absolute;
                                        top: -19px;
                                        right: -10px;
                                        width: 20px;
                                        height: 20px;
                                        background-size: contain;
                                        @include transition(all 0.5s ease);
                                        z-index: 1;

                                        &:hover {
                                            @include transform(rotate(360deg));
                                        }
                                    }

                                    .edit {
                                        position: absolute;
                                        top: 6px;
                                        right: 28px;
                                        @include transition(all 0.5s ease);

                                        &:hover {
                                            @include transform(rotate(360deg));
                                        }
                                    }
                                }

                                .ad {
                                    display: inline-block;
                                    width: 250px;
                                    margin-right: 20px;
                                    text-align: center;
                                    margin-bottom: 15px;

                                    .title {
                                        text-align: left;
                                    }

                                    .image {
                                        width: 250px;
                                        height: 140px;
                                        @include background-size(contain !important);
                                        background-position: center center !important;
                                        background-repeat: no-repeat !important;
                                        background-color: $white !important;
                                    }

                                    .duration {
                                        margin: 10px auto 0;
                                    }

                                    .duration,
                                    .page {
                                        text-align: left;

                                        span {
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.create,
                    &.edit {
                        .background-picture {
                            &:hover {
                                img {
                                    @include transition(all 0.3s ease);
                                    @include transform(scale(2.5));
                                }
                            }

                            img {
                                @include transition(all 0.3s ease);
                            }
                        }

                        .bg-image {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
