body {
    #app {
        .content-container {
            .equipment {
                .panel {
                    .panel-body {
                        .grid-item,
                        .new-item {
                            height: 250px;
                        }

                        .equipment-text {
                            width: 92%;
                        }

                        .sortable {
                            li {
                                &::after {
                                    content: '';
                                    background: url(/icons/move.svg);
                                    height: 20px;
                                    background-repeat: no-repeat;
                                    width: 20px;
                                    display: block;
                                    position: absolute;
                                    @include background-size(100%);
                                    top: 50%;
                                    left: 50%;
                                    @include transform(translate(-50%, -50%));
                                    @include opacity(0);
                                    @include transition(all 0.5s ease);
                                }

                                &:hover {
                                    &::after {
                                        @include opacity(1);
                                    }
                                }
                            }
                        }

                        .list {
                            list-style: none;
                            padding: 0;
                            margin: 0;
                            position: relative;

                            li {
                                background: $white;
                                padding: 7px 12px;
                                position: relative;
                                height: 35px !important;
                                margin-bottom: 5px;
                                cursor: pointer;
                                width: 100% !important;
                                border: 1px solid $border-gray;

                                a {
                                    color: $black;
                                    font-weight: 600px;

                                    &:hover {
                                        text-decoration: none;
                                    }
                                }

                                .cross-button {
                                    background: url(/images/trash.svg);
                                    @include background-size(100%);
                                    background-color: transparent;
                                    width: 23px;
                                    height: 23px;
                                    background-repeat: no-repeat;
                                    position: absolute;
                                    top: 5px;
                                    right: 9px;
                                    @include transition(all 0.5s ease);
                                    outline: none;

                                    &:hover {
                                        @include transform(rotate(360deg));
                                    }
                                }

                                .comments {
                                    position: absolute;
                                    right: 39px;
                                    top: 5px;

                                    button {
                                        background: url(/icons/comments.svg);
                                        background-color: transparent;
                                        border: 0;
                                        @include background-size(100%);
                                        width: 24px;
                                        height: 24px;
                                        @include transition(all 0.5s ease);
                                        outline: none;

                                        &:hover {
                                            @include transform(rotate(360deg));
                                        }
                                    }
                                }
                            }
                        }

                        .custom-equip {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
