// Colors

$white: #fff;
$black: #000;
$main-bg: #F4F6FF;
$blue: #666768;
$dark-black: #131A20;
$orange: #ED9437;
$light-gray: #eee;
$medium-light-gray: #ccc;

// Body

$body-bg: #f5f8fa;

// Borders

$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands

$brand-primary: #131A20;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography

$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Body

$a4f-body: #f4f6ff;

// Buttons

$btn-default-color: $text-color;

// Inputs

$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels

$text-color: #40434b;

// Dashboard

$green: #54CE6A;
$red: #ff0000;
$border-gray: #d8d8d8;
$dark-white: #fafafa;
$shadow: #888888;