body {
    #app {
        .content-container {
            .quiz {
                .panel {
                    .panel-body {
                        .quiz-item {
                            .quiz-content {
                                height: 80px;
                                padding-left: 0;

                                .cross-button {
                                    top: 9px;
                                    right: 7px
                                }
                                
                                .info {
                                    padding-left: 10px;
                                    
                                    .name {
                                        font-size: 18px;
                                        font-weight: 500;
                                    }
                                }
                                    
                                .edit {
                                    top: 12px;
                                    right: 12px;
                                }

                                .quiz_img {
                                    display: block;
                                    width: 80px;
                                    height: 78px;
                                }
                            }
                        }

                        .preview {
                            width: 300px;
                        }
                    }
                }

                &.create {
                    .question,
                    .answer {
                        display: none;
                        
                        .input-question,
                        .input-answer {
                            position: relative;

                            img {
                                width: 15px;
                                position: absolute;
                                top: 12px;
                                right: 10px;
                                cursor: pointer;
                            }
                        }
                    }

                    .answers {
                        text-align: right;

                        .answer-label {
                            display: block;
                        }

                        .new-answer {
                            width: 147px !important;
                        }
                    }

                    .answer-block {
                        margin-top: 20px;
                        text-align: right;

                        .input-answer {
                            width: 335px;
                            display: inline-block;

                            img {
                                top: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .quiz-stats {
        .panel-heading {
            border-bottom: 1px solid $orange !important;
            padding: 0;
            text-align: left !important;
            margin-bottom: 20px;
            font-size: 22px !important;
        }

        .info {
            width: 100%;
            margin-bottom: 35px;

            .image {
                display: inline-block;
                vertical-align: middle;

                div {
                    width: 100px;
                    height: 100px;
                    background-size: cover !important;
                    background-repeat: no-repeat !important;
                    background-position: center center !important;
                }
            }

            .text {
                display: inline-block;
                margin-left: 20px;
                vertical-align: middle;
                text-align: left;

                .info-title {
                    font-size: 20px;
                    font-weight: 600;
                }

                .info-description,
                .info-term,
                .info-winner {
                    span {
                        font-weight: 600;
                    }
                }

                .info-winner {
                    color: green;
                }
            }
        }

        .question-title {
            font-size: 18px;
            font-weight: 600;
            text-align: left;
            border-bottom: 1px solid $border-gray;
            padding-bottom: 2px;
            margin-bottom: 15px;
        }

        .quiz-answer-0,
        .quiz-answer-1,
        .quiz-answer-2,
        .quiz-answer-3 {
            position: relative;
            margin-bottom: 12px;
            text-align: left;
            margin-left: 34px;

            &::before {
                position: absolute;
                content: "";
                display: inline-block;
                width: 25px;
                height: 25px;
                border-radius: 100%;
                left: -34px;
                top: -2px;
            }
        }

        .quiz-answer-0 {
            &::before {
                background: $orange;
            }
        }

        .quiz-answer-1 {
            &::before {
                background: #b3b3b3;
            }
        }

        .quiz-answer-2 {
            &::before {
                background: $dark-black;
            }
        }

        .quiz-answer-3 {
            &::before {
                background: $brand-danger;
            }
        }

        .question {
            width: 100%;
            text-align: left;
            border-bottom: 1px solid $border-gray;
            margin-bottom: 15px;
            padding-bottom: 2px;
        }

        .chart {
            canvas {
                width: 150px !important;
                height: 150px !important;
            }
        }
        
        .question,
        .chart {
            display: inline-block;
            vertical-align: middle;
        }

        .question-container {
            width: 30.7%;
            text-align: center;
            @include box-shadow(0 0 100px $shadow);
            margin: 10px;
            @include border-radius(10px);
            padding: 15px;
            display: inline-block;

            @media screen and (max-width: 797px) {
                width: 46%;
            }

            @media screen and (max-width: 520px) {
                width: 100%;
            }

            .novotes {
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 150px;
                width: 150px;
                border: 1px solid $orange;
            }

            .correct {
                color: green;
            }
        }
    }
}