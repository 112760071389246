body {
    .color-block {
        display: none;
    }
    
    .color-selections {
        display: flex;
        flex-direction: row;

        .color-selection-wrapper {
            @include transition(all .2s);
            margin-right: 10px;
            padding: 5px;
            border: 1px solid #fff;

            .color-selection {
                cursor: pointer;
                height: 35px;
                width: 85px;
                margin-bottom: -5px; 
            }

            input {
                display: none;
            }

            &:hover,
            &.selected {
                border: 1px solid #000000;
            }
        }
    }

    .toggles {
        display: flex;
        flex-direction: row;

        .radio-button {
            width: 80px;
            height: 35px;
            position: relative;
            border-radius: 0;
            border: none;
            color: $text-color;
            
            &.active {
                background-color: $blue;
                color: #ffffff;
            }
        }

        input {
            position: absolute;
            visibility: hidden;
        }

        input[type="radio"]:checked + label {
            background-color: $blue;
            color: #ffffff;
        }
    }

    .doyou {
        text-align: center;
        font-weight: 600;
        margin-bottom: 10px;
    }
}
