.dashboard {
    width: 100%;

    .topclick {
        .name {
            width: 66%;

            @media screen and (max-width: 570px) {
                width: 64%;
            }
        }
    }

    .select-time {
        text-align: center;
        width: 100%;
        position: relative;
        margin-bottom: 17px;

        span {
            display: inline-block;
            width: 250px;
            text-align: center;
            background: $light-gray;
            padding: 10px 20px 10px 10px;
            position: relative;
            cursor: pointer;
            border: 1px solid $border-gray;

            &::before {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid $blue;
                @include transition(all 0.3s ease);
                content: "";
                position: absolute;
                right: 10px;
                top: 19px;
            }

            &.active {
                &::before {
                    @include transform(rotate(180deg));
                }
            }
        }

        ul {
            width: 250px;
            padding: 0;
            margin: 5px auto;
            background: $light-gray;
            list-style-type: none;
            display: none;
            position: absolute;
            left: 50%;
            @include transform(translateX(-50%));
            z-index: 1;
            border: 1px solid $border-gray;

            li {
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid $border-gray;
                @include transition(all 0.3s ease);
                cursor: pointer;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background: $border-gray;
                }
            }
        }
    }

    .header {
        color: $white;
        background: linear-gradient($blue, $dark-black) !important;
        height: 40px;
        padding-top: 7px !important;
        border-bottom: none;

        .value {
            font-weight: normal;
        }
    }

    .topeffect {
        .view-click {
            @media screen and (max-width: 570px) {
                display: none;
            }
        }

        .name {
            @media screen and (max-width: 570px) {
                width: 64%;
            }
        }
    }

    .topclick,
    .topeffect,
    .topcountry {
        width: 47.6%;
        display: inline-block;
        //margin-left: 18px;
        margin-top: 25px;
        vertical-align: top;

        @media screen and (max-width: 510px) {
            font-size: 10px;
        }

        @media screen and (max-width: 1200px) {
            width: 96%;
            padding: 0 25px;
        }

        @media screen and (max-width: 991px) {
            width: 100%;
            padding: 0;
            margin: 25px 0 0;
        }

        .top-title {
            font-weight: 700;
            margin: 15px 0 10px;
            font-size: 15px;
        }

        .entry {
            height: 40px;
            padding-top: 8px;

            &.header {
                min-height: auto;
            }

            &.empty {
                text-align: center;
            }

            @media screen and (max-width: 510px) {
                height: 32px;
                padding-top: 6px;
            }

            &:last-child {
                border-bottom: none;
            }

            &:nth-child(even) {
                background: $light-gray;
            }
        }

        .head {
            font-weight: normal;
        }
    }

    .topcountry {
        margin-top: 0px;
    }

    .value {
        width: 13%;
        text-align: center;
    }

    .rank {
        width: 9%;
        text-align: center;

        img {
            width: 26px;
            margin-right: 15px;
            margin-left: 13px;

            @media screen and (max-width: 510px) {
                width: 20px;
                margin: 0 7px;
            }
        }

        span {
            font-weight: 600;
        }
    }

    .view-click {
        width: 22%;
        text-align: center;
    }

    .image {
        width: 9%;

        @media screen and (max-width: 570px) {
            width: 11%;
        }

        div {
            @include box-shadow(0 0 7px 2px rgba(196, 196, 196, 1));
            width: 24px;
            height: 24px;
            @include background-size(cover !important);
            background-repeat: no-repeat !important;
            background-position: center center !important;
            @include border-radius(100%);
            margin-left: 6px;

            @media screen and (max-width: 570px) {
                width: 20px;
                height: 20px;
            }
        }

        img {
            height: 23px;
            margin-left: 5px;

            @media screen and (max-width: 510px) {
                height: 20px;
            }
        }
    }

    .name {
        width: 44%;
    }

    .value {
        font-weight: 600;
    }

    .field {
        display: inline-block;
        vertical-align: middle;
    }

    // .blue-progress-bar {
    //     position: relative;
    //     text-align: center;
    //     display: inline-block;

    //     .barOverflow {
    //         position: relative;
    //         overflow: hidden;
    //         width: 440px;
    //         height: 220px;
    //         margin-bottom: -14px;

    //         @media screen and (max-width: 1175px) {
    //             width: 200px;
    //             height: 100px;
    //         }

    //         .bar {
    //             position: absolute;
    //             top: 0;
    //             left: 0;
    //             width: 440px;
    //             height: 440px;
    //             @include border-radius(50%);
    //             border: 80px solid $light-gray;
    //             border-bottom-color: $blue;
    //             border-right-color: $blue;

    //             @media screen and (max-width: 1175px) {
    //                 border: 40px solid $light-gray;
    //                 width: 200px;
    //                 height: 200px;
    //                 border-bottom-color: $blue;
    //                 border-right-color: $blue;
    //             }
    //         }
    //     }

    //     .info {
    //         font-size: 42px;
    //         font-weight: 700;
    //         line-height: 42px;
    //         top: -46px;
    //         position: relative;

    //         @media screen and (max-width: 1175px) {
    //             font-size: 30px;
    //             line-height: 30px;
    //             top: 0;
    //         }

    //         .up {
    //             font-size: 14px;
    //             line-height: 1.6;
    //             font-weight: normal;

    //             @media screen and (max-width: 1175px) {
    //                 margin-bottom: 10px;
    //                 padding-top: 3px
    //             }

    //             span {
    //                 color: $green;
    //                 font-weight: 700;
    //                 font-style: italic;
    //                 position: relative;
    //                 font-size: 14px;
    //                 line-height: 1.6;

    //                 &::before {
    //                     width: 0;
    //                     height: 0;
    //                     border-left: 4px solid transparent;
    //                     border-right: 4px solid transparent;
    //                     border-bottom: 4px solid $green;
    //                     content: "";
    //                     position: absolute;
    //                     left: -11px;
    //                     top: 6px;
    //                 }
    //             }
    //         }

    //         &.down {
    //             font-size: 14px;
    //             line-height: 1.6;
    //             font-weight: normal;

    //             @media screen and (max-width: 1175px) {
    //                 margin-bottom: 10px;
    //                 padding-top: 3px
    //             }

    //             span {
    //                 color: $red;
    //                 font-weight: 700;
    //                 font-style: italic;
    //                 position: relative;
    //                 font-size: 14px;
    //                 line-height: 1.6;

    //                 &::before {
    //                     width: 0;
    //                     height: 0;
    //                     border-left: 4px solid transparent;
    //                     border-right: 4px solid transparent;
    //                     border-top: 4px solid $red;
    //                     content: "";
    //                     position: absolute;
    //                     left: -11px;
    //                     top: 6px;
    //                 }
    //             }
    //         }
    //     }

    //     .empty {
    //         position: absolute;
    //         bottom: 20px;
    //         left: 36px;

    //         @media screen and (max-width: 1175px) {
    //             bottom: 33px;
    //             left: 18px;
    //             font-size: 11px;
    //         }
    //     }

    //     .full {
    //         position: absolute;
    //         bottom: 20px;
    //         right: 27px;

    //         @media screen and (max-width: 1175px) {
    //             bottom: 33px;
    //             right: 10px;
    //             font-size: 11px;
    //         }
    //     }
    // }

    .bottom {
        width: 47.661%;
        display: inline-block;
        vertical-align: top;
        text-align: left;
        margin: 0 0 25px 18px;
        position: relative;
        text-align: center;

        @media screen and (max-width: 1200px) {
            width: 96% !important;
        }

        @media screen and (max-width: 991px) {
            width: 100% !important;
            margin: 0 0 35px 0;
        }

        .donut {
            width: 46%;
            display: inline-block;
            height: 400px;
            text-align: center;

            @media screen and (max-width: 480px) {
                width: 100%;
            }

            @media screen and (max-width: 991px) {
                margin-top: 15px;
            }

            &:first-child {
                margin-right: 15px;
            }

            &:last-child {
                @media screen and (max-width: 480px) {
                    margin-top: 100px;
                }
            }

            span {
                font-weight: 700;
                margin: 20px 0 10px;
                font-size: 15px;
                display: block;
            }

            .legend {
                div {
                    display: inline-block;
                    font-size: 12px;
                    position: relative;
                }

                .entry-1 {
                    &::before {
                        content: "";
                        background: $orange;
                        height: 15px;
                        width: 15px;
                        border-radius: 100%;
                        display: block;
                        position: absolute;
                        left: -20px;
                        top: 1px;
                    }
                }

                .entry-2 {
                    margin: 0 25px 8px 20px;

                    &::before {
                        content: "";
                        background: $blue;
                        height: 15px;
                        width: 15px;
                        border-radius: 100%;
                        display: block;
                        position: absolute;
                        left: -20px;
                        top: 1px;
                    }
                }
            }
        }

        .bar-graph {
            width: 400px;
            display: inline-block;
            height: 400px;
            text-align: center;

            @media screen and (max-width: 480px) {
                width: 100%;
            }

            @media screen and (max-width: 991px) {
                margin-top: 15px;
            }

            &:first-child {
                margin-right: 15px;
            }

            &:last-child {
                @media screen and (max-width: 480px) {
                    margin-top: 100px;
                }
            }

            span {
                font-weight: 700;
                margin: 20px 0 10px;
                font-size: 15px;
                display: block;
            }

            .legend {
                div {
                    display: inline-block;
                    font-size: 12px;
                    position: relative;
                }

                .entry-1 {
                    &::before {
                        content: "";
                        background: $orange;
                        height: 15px;
                        width: 15px;
                        border-radius: 100%;
                        display: block;
                        position: absolute;
                        left: -20px;
                        top: 1px;
                    }
                }

                .entry-2 {
                    margin: 0 25px 8px 20px;

                    &::before {
                        content: "";
                        background: $blue;
                        height: 15px;
                        width: 15px;
                        border-radius: 100%;
                        display: block;
                        position: absolute;
                        left: -20px;
                        top: 1px;
                    }
                }
            }
        }

        .topcountry {
            width: 100%;
            margin: 0;
            text-align: left;
            min-height: 280px;

            .top-title {
                margin-bottom: 10px;
                text-align: left;

                @media screen and (max-width: 1200px) {
                    margin-top: 80px;
                }
            }

            .name {
                width: 66%;

                @media screen and (max-width: 570px) {
                    width: 64%;
                }
            }
        }

        @media screen and (max-width: 1175px) {
            border: none;
            background: transparent;
            width: 45.661%;
        }

        @media screen and (max-width: 650px) {
            width: 100%;
            margin: 25px 0;
        }
    }

    .monthly-title {
        font-weight: 700;
        text-align: left;

        @media screen and (max-width: 1175px) {
            text-align: center;
            margin-bottom: 10px;
        }
    }

    .appstore,
    .gplay,
    .total,
    .views,
    .facebook,
    .clicks,
    .ads,
    .pviews {
        border-right: 1px solid $border-gray;
    }

    .gplay,
    .ads,
    .clicks {
        @media screen and (max-width: 830px) {
            border-right: none;
        }
    }

    .second-row,
    .first-row {
        text-align: center;
    }

    .counter {
        display: inline-block;
        text-align: center;
        width: 23.791%;
        vertical-align: top;

        @media screen and (max-width: 830px) {
            width: 48.791%;
            margin-bottom: 25px;
        }

        @media screen and (max-width: 440px) {
            width: 100%;
            border-right: none;
        }

        .count {
            font-size: 42px;
            font-weight: 700;
            line-height: 42px;
        }

        .week-changes {
            &.up {
                span {
                    color: $green;
                    font-weight: 600;
                    font-style: italic;
                    position: relative;

                    &::before {
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-bottom: 4px solid $green;
                        content: "";
                        position: absolute;
                        left: -11px;
                        top: 6px;
                    }
                }
            }

            &.down {
                span {
                    color: $red;
                    font-weight: 600;
                    font-style: italic;
                    position: relative;

                    &::before {
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-top: 4px solid $red;
                        content: "";
                        position: absolute;
                        left: -11px;
                        top: 6px;
                    }
                }
            }
        }
    }
}
