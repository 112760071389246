.picker-bg {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.5);
    display: none;
    position: fixed;
    z-index: 1;
    top: 0;
}

.xdsoft_datetimepicker {
	background: $blue;
	color: $black;
	position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    @include transform(translate(-50%, -50%));
	z-index: 9999;
	display: none;
	padding: 10px;

    .xdsoft_monthpicker {
        position: relative;
        display: inline-block;
        width: 100%;
        color: $black;
        padding-bottom: 5px;

        .xdsoft_prev,
        .xdsoft_next {
            display: none;
        }

        .xdsoft_label {
            span {
                &::after {
                    content: '';
                    background: url(/fonts/arrow2.svg?14a1645…);
                    background-size: 100%;
                    height: 9px;
                    width: 10px;
                    margin-left: 5px;
                    display: inline-block;
                }
            }
        }
    }

    .xdsoft_datepicker {
        width: 260px;
        float: left;
        display: none;

        &.active {
            display: block;
        }

        @media screen and (max-width: 700px) {
            width: 100%;
        }

        &.active + .xdsoft_timepicker {
            padding: 22px 0 0 0;
            margin: 0;
        }
    }

    .xdsoft_label {
        display: inline;
        position: relative;
        z-index: 9999;
        margin: 0;
        padding: 0px 15px;
        position: relative;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        top: -2px;
        color: $white;

        > .xdsoft_select {
            border: 1px solid $medium-light-gray;
            position: absolute;
            font-size: 12px;
            text-align: left;
            right: 0;
            top: 27px;
            z-index: 101;
            display: none;
            background: $white;
            color: $black;
            max-height: 160px;
            overflow-y: hidden;

            > div {
                > .xdsoft_option {
                    padding: 2px 10px 2px 5px;

                    &.xdsoft_current {
                        background: $orange;
                    }

                    &:hover {
                        background: $orange;
                    }
                }
            }
        }
    }

    .xdsoft_timepicker {
        width: 70px;
        float: left;
        text-align: center;
        display: none;

        &.active {
            display: block;
        }

        .xdsoft_next,
        .xdsoft_prev {
            display: none;
        }

        @media screen and (max-width: 700px) {
            width: 100%;
            margin-top: 0 !important;
        }

        .xdsoft_time_box {
            height: 151px;
            overflow: hidden;
            margin: 5px 0 0 10px;
            background: $white;
            border: 0;

            @media screen and (max-width: 700px) {
                margin: 0;
            }

            > div > div {
                border-top: 1px solid $medium-light-gray;
                color: $black;
                font-size: 12px;
                text-align: center;
                border-collapse: collapse;
                cursor: pointer;
                border-bottom-width: 0;
                height: 25px;
                line-height: 25px;
                
                &:hover {
                    background: $orange !important;
	                box-shadow: none !important;
                }

                &.xdsoft_current {
                    background: $orange;
	                font-weight: 500;
                }
            }
        }
    }

    .xdsoft_calendar {
        table {
            border-collapse: collapse;
            width: 100%;
        }

        th {
            width: 14.2857142%;
            background: $medium-light-gray;
            border: 1px solid $border-gray;
            color: $black;
            font-size: 12px;
            text-align: center;
            vertical-align: middle;
            padding: 0;
            border-collapse: collapse;
            cursor: pointer;
            height: 25px;
        }

        td {
            width: 14.2857142%;
            background: $white;
            border: 1px solid $border-gray;
            color: $black;
            font-size: 12px;
            text-align: center;
            vertical-align: middle;
            padding: 0;
            border-collapse: collapse;
            cursor: pointer;
            height: 25px;

            > div {
                padding-right: 5px;
            }
            
            &:hover {
                background: $orange !important;
	            box-shadow: none !important;
            }

            &.xdsoft_default,
            &.xdsoft_current {
                background: $orange;
	            font-weight: 500;
            }
        }
    }

    .xdsoft_other_month {
        div {
            color: $text-color;
        }
    }

    * {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    .xdsoft_today_button {
        float: left;
        background: url(/icons/home.svg);
        @include background-size(100%);
        background-repeat: no-repeat;
        width: 17px;
        height: 17px;
        background-color: transparent;
	    border: 0 none;
    }

    .xdsoft_month {
        width: 100px;
        text-align: right;
    }

    .xdsoft_year {
        width: 48px;
        margin-left: 5px;
    }

    .xdsoft_time_box {
        position: relative;
    }

    .xdsoft_scrollbar {
        position: absolute;
        width: 7px;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;

        > .xdsoft_scroller {
            background: $medium-light-gray !important;
            height: 20px;
        }
    }
}

.xdsoft_noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}