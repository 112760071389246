body {
    #app {
        .content-container {
            .list {
                .panel {
                    .panel-body {
                        .list-item {
                            position: relative;

                            .content {
                                background: $white;
                                height: 35px;
                                vertical-align: middle;
                                display: flex;
                                align-items: center;
                                padding-left: 10px;
                                margin-bottom: 5px;
                                position: relative;
                                border: 1px solid $border-gray;
                                
                                .edit {
                                    position: absolute;
                                    top: 6px;
                                    right: 10px;
                                    margin: 0;
                                    @include transition(all 0.5s ease);

                                    &:hover {
                                        @include transform(scale(1.1));
                                    }
                                }

                                .stats,
                                .stats-button {
                                    background: url(/images/stats.svg);
                                    @include background-size(100%);
                                    background-color: transparent;
                                    width: 27px;
                                    height: 34px;
                                    background-repeat: no-repeat;
                                    position: absolute;
                                    top: -3px;
                                    right: 38px;
                                    @include transition(all 0.5s ease);
                                    cursor: pointer;
                                    outline: none;
                                    
                                    &:hover {
                                        @include transform(scale(1.1));
                                    }
                                }

                                .userStats-button {
                                    background: url(/images/userStats.svg);
                                    @include background-size(100%);
                                    background-color: transparent;
                                    width: 25px;
                                    height: 34px;
                                    background-repeat: no-repeat;
                                    position: absolute;
                                    top: -2px;
                                    right: 74px;
                                    @include transition(all 0.5s ease);
                                    cursor: pointer;
                                    outline: none;
                                    
                                    &:hover {
                                        @include transform(scale(1.1));
                                    }
                                }

                                .stats-button {
                                    top: -8px;
                                }

                                .cross-button {
                                    background: url(/images/trash.svg);
                                    @include background-size(100%);
                                    background-color: transparent;
                                    width: 23px;
                                    height: 23px;
                                    background-repeat: no-repeat;
                                    position: absolute;
                                    top: 5px;
                                    right: 9px;
                                    @include transition(all 0.5s ease);
                                    outline: none;

                                    &:hover {
                                        @include transform(scale(1.1));
                                    }
                                }

                                .comments {
                                    position: absolute;
                                    right: 39px;
                                    top: 5px;

                                    button {
                                        background: url(/icons/comments.svg);
                                        background-color: transparent;
                                        border: 0;
                                        @include background-size(100%);
                                        width: 24px;
                                        height: 24px;
                                        @include transition(all 0.5s ease);
                                        outline: none;

                                        &:hover {
                                            @include transform(rotate(360deg));
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}