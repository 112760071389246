.sidebar-container {
    background-color: $blue;
    background: linear-gradient($blue, $dark-black);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    flex: 0 0 240px;
    box-sizing: content-box;
    position: absolute;
    height: 100%;
    width: 240px;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 1;
    min-height: 100%;

    .sidebar-wrapper {
        color: #ffffff;
        display: flex;
        flex-direction: column;

        .app-selection {
            padding: 0 20px 10px;
        }

        .sidebar-top {
            color: #ffffff;
            display: flex;
            flex-direction: column;

            .branding {
                padding: 23px 20px 0;
                display: flex;

                i {
                    background-image: url('icons/menu.svg');
                    height: 25px;
                    width: 25px;
                    margin: 0 10px;
                    background-repeat: no-repeat;
                }

                a {
                    color: #ffffff;
                    height: unset;
                    font-size: 17px;
                    line-height: 20px;
                }

                .navbar-brand {
                    padding: 0 10px;
                }
            }

            .profile-wrapper {
                font-size: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 20px;

                .profile-picture {
                    height: 72px;
                    width: 72px;
                    border-radius: 50%;
                    @include background-size(cover);
                    border: 3px solid #ffffff;
                    margin-right: 5px;
                }

                span {
                    font-weight: 500;
                    font-style: italic;
                    text-transform: uppercase;
                    padding-left: 3px;
                    line-height: 16px;
                }
            }
        }

        .settings-wrapper{
            a {
                color: $white;

                &:hover {
                    text-decoration: none;
                }
            }

			.space {
				height:25px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
			}

            ul {
                padding: 0;

                .setting {
                    cursor: pointer;
                    font-size: 14px;
                    height: 45px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                    @include transition(all 0.5s ease);

                    .setting-text-wrapper {
                        padding: 0 25px;
                        width: 100%;
                    }

                    &:hover,
                    &.active-sub {
                        background-color: rgba(255, 255, 255, 0.1);
                    }

                    &.accordion {
                        &::after {
                            content: "";
                            height: 15px;
                            width: 15px;
                            margin: 6px 10px 0;
                            background-image: url('icons/arrow2.svg');
                            margin: 6px 10px 0;
                            background-repeat: no-repeat;
                        }

                        &.active {
                            &::after {
                                background-image: url('icons/arrow1.svg');
                            }
                        }
                    }
                }

                 .children,
                 .subsetting {
                    display: none;
                    overflow: hidden;
                    @include transition(max-height 0.2s ease-out);

                    ul {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        a {
                            color: #ffffff;

                            &:hover {
                                text-decoration: none;
                                color: #ffffff;
                            }

                            li {
                                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                                cursor: pointer;
                                font-size: 14px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                list-style-type: none;
                                height: 45px;

                                .subsetting-text-wrapper {
                                    padding: 0 25px;
                                }

                                &:hover {
                                    background-color: rgba(255,255,255,0.1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
