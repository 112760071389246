body {
    #app {
        .content-container {
            .grid {
                .panel {
                    .panel-body {
                        .content {
                            background: $white;
                            height: 35px;
                            vertical-align: middle;
                            display: flex;
                            align-items: center;
                            padding-left: 10px;
                            margin-bottom: 5px;
                            position: relative;
                        }

                        .grid-item {
                            width: 300px;
                            height: 275px;
                            padding: 15px;
                            background: $white;
                            margin: 0 15px 15px 0;
                            vertical-align: top;
                            display: inline-block;
                            text-align: center;
                            position: relative;
                            border: 1px solid $border-gray;


                            .cross-button {
                                background: url(/images/trash.svg);
                                background-color: transparent;
                                @include background-size(100%);
                                width: 24px;
                                height: 24px;
                                background-repeat: no-repeat;
                                position: absolute;
                                bottom: 11px;
                                right: 117px;
                                @include transition(all 0.5s ease);

                                &:hover {
                                    @include transform(scale(1.1));
                                }
                            }

                            .comments {
                                position: absolute;
                                left: 127px;
                                bottom: 6px;

                                button {
                                    background: url(/icons/comments.svg);
                                    background-color: transparent;
                                    border: 0;
                                    @include background-size(100%);
                                    width: 23px;
                                    height: 23px;
                                    @include transition(all 0.5s ease);
                                    outline: none;

                                    &:hover {
                                        @include transform(scale(1.1));
                                    }
                                }
                            }

                            .messages {
                                position: absolute;
                                left: 127px;
                                bottom: 6px;

                                button {
                                    background: url(/icons/msg.svg);
                                    background-color: transparent;
                                    border: 0;
                                    @include background-size(100%);
                                    width: 23px;
                                    height: 23px;
                                    @include transition(all 0.5s ease);
                                    outline: none;

                                    &:hover {
                                        @include transform(rotate(360deg));
                                    }
                                }
                            }

                            .edit {
                                position: absolute;
                                bottom: 12px;
                                left: 125px;

                                img {
                                    width: 23px;
                                    @include transition(all 0.5s ease);

                                    &:hover {
                                        @include transform(rotate(360deg));
                                    }
                                }
                            }

                            .grid-item-title {
                                font-size: 20px;
                                line-height: 22px;
                                margin-bottom: 10px;
                                font-weight: 500;
                            }

                            .image {
                                width: 100%;
                                height: 150px;
                                max-height: 150px;
                                height: 100%;
                            }
                        }

                        .new-item {
                            border: 1px solid $blue;
                            background: transparent;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            width: 300px;
                            height: 275px;

                            &:hover {
                                img {
                                    @include transition(all 0.3s ease);
                                    @include transform(scale(2.5));
                                }
                            }

                            img {
                                @include transition(all 0.3s ease);
                                width: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
}
