.navbar {
    height: 80px;
    background-color: $blue;
    border-width: 0 0 1px;
    z-index: 0;
    .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            height: 100%;

            .navbar-nav > li {
                a {
                    color: #ffffff;
                }
            }

        .dropdown {
            .dropdown-toggle{
                color: #ffffff;
            }

            i {
                background-image: url('icons/notifications.svg');
                height: 25px;
                width: 25px;
                margin: 0 10px;
                background-repeat: no-repeat;
            }

            &.open {
                a {
                    color: #333333;
                }
            }
        }
    }
}
