.athletes {
    width: 100%;
    border-left: 1px solid $light-gray;
    background: $white;
    border-collapse: collapse !important;
    font-size: 13px;

    .top-heading {
        .reports {
            margin-top: 0;
            margin-bottom: 11px;

            .custom-select {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                position: relative;
                top: 14px;
            }
        }
    }

    &.no-footer {
        border-bottom: none;
    }

    .push {
        width: 110px !important;
        text-transform: capitalize;
    }

    .date {
        width: 140px !important;
    }

    th,
    td {
        padding: 10px;
        border-right: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
    }
    
    th {
        background: $light-gray;
        border-bottom: none !important;
    }

    tbody {
        tr {
            cursor: pointer;
            @include transition(all .3s ease);

            &:hover {
                background: $dark-white;
            }
        }
    }

    thead {
        .sorting_asc {
            background-image: url(/images/sort_asc.png) !important;
        }

        .sorting_desc {
            background-image: url(/images/sort_desc.png) !important;
        }
    }

    .center {
        text-align: center;

        img {
            width: 20px;
            height: 20px;
            
            &.no {
                width: 17px;
                height: 17px;
            }
        }
    }
}

.dataTables_filter {
    @media screen and (max-width: 640px) {
        text-align: left !important;
        margin-top: 0 !important;
        margin-bottom: 7px;
    }
    
    label {
        input {
            font-weight: normal;
            padding: 5px 10px;
        }
    }
}