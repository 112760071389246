// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables

@import "splits/variables";

// Mixins

@import "splits/mixins";

// Bootstrap

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Layout

@import 'layout/list';
@import 'layout/grid';

// Splits

@import 'splits/navbar';
@import 'splits/sidebar';
@import 'splits/general';
@import 'splits/adsettings';
@import 'splits/welcome';
@import 'splits/polls';
@import 'splits/quiz';
@import 'splits/photos';
@import 'splits/shop';
@import 'splits/equipment';
@import 'splits/career';
@import 'splits/support';
@import 'splits/datetimepicker';
@import 'splits/dashboard';
@import 'splits/athletes';

// Global Styling

// body #app .content-container .container .panel .panel-body input[type=text], body #app .content-container .container .panel .panel-body input[type=password], body #app .content-container .container .panel .panel-body input[type=number], body #app .content-container .container .panel .panel-body input[type=search], body #app .content-container .container .panel .panel-body select {
//     display:none;
// }

body {

    .marginleftright20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .hide-filters {
        display: none;
    }

    .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
       border: 0 !important;
    }

    .select2 span {
        height: auto;
    }

    @media (min-width: 590px) {

        .select2-container--bootstrap.select2-container--focus .select2-selection, .select2-container--bootstrap.select2-container--open .select2-selection {
            height: 36.5px;
        }

        .filters-margin {
            margin-bottom: 20px;
        }

        .paddingleft30 {
            padding-left:30px;
        }

        .paddingtop10 {
            padding-top:10px;
        }

        .paddingtop27 {
            padding-top:27px;
        }

        // .paddingtop3 {
        //     padding-top:3px;
        // }

        .width100 {
            width: 100%;
        }

    }

    .select2-container--open
    {
        z-index: 9000;
    }

    background-color: $a4f-body;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
    max-width: 1400px;
    margin: auto;
    background: $light-gray;
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;

    .fancybox-overlay {
        background: rgba(0, 0, 0, 0.8);
    }

    .fancybox-skin {
        background: url(/images/popup_bg.png) !important;
        background-size: cover;
        @include background-size(cover);
        border: 1px solid #6b6b6b;
        @include box-shadow(0 0px 100px rgba(255, 255, 255, 0.5) !important);
        @include border-radius(0);

        .text {
            color: $white;
            text-align: center;
            margin: 20px auto;
            font-weight: 600;
            text-shadow: 1px 1px 4px #3a3a3a;
        }

        .logo {
            text-align: center;
            margin-top: 10px;

            img {
                width: 130px;
            }
        }

        .mb-md {
            margin-bottom: 10px;
        }

        form {
            .button {
                background: $orange;
                border: 0;
                color: $white;
                font-weight: bold;
                font-size: 14px;
                padding: 3px 21px;
                border: 1px solid $orange;
                @include transition(all 0.2s ease);

                &:hover {
                    background: transparent;
                    color: $orange;
                }
            }
        }

        .yesno {
            text-align: center;
            margin-bottom: 10px;

            .yes,
            .no {
                display: inline-block;
                vertical-align: top;
            }

            .no {
                background: $blue;
                border: 0;
                color: $white;
                font-weight: bold;
                font-size: 14px;
                padding: 3px 21px;
                cursor: pointer;
                margin-left: 10px;
                border: 1px solid $blue;
                @include transition(all 0.2s ease);

                &:hover {
                    background: transparent;
                    color: $white;
                    border: 1px solid $white;
                }
            }
        }
    }

    .change-td {
        text-align: center;
    }

    .change-to-athlete {
        background-image: url(/icons/start.svg);
        height: 18px;
        width: 18px;
        background-size: cover;
        border: 0;
        margin: auto;
        background-repeat: no-repeat;
    }

    .help-block {
        color: red;
    }

    .custom-checkbox {
        margin-bottom: 3px;

        label {
            position: relative;
            margin-left: 20px;
            height: 23px;
            cursor: pointer;

            &::before{
                width: 25px;
                height: 25px;
                border-radius: 5px;
                background: $border-gray;
                content: "";
                display: block;
                position: absolute;
                left: -33px;
                top: -1px;
            }
        }

        input {
            visibility: hidden;

            &:checked + label {
                &::before {
                    background: $blue;
                }

                &::after {
                    border: solid $orange;
                    border-width: 0px 3px 3px 0px;
                    display: inline-block;
                    padding: 4px;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    content: "";
                    position: absolute;
                    left: -26px;
                    top: 4px;
                }
            }
        }
    }

    .top-heading {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid $orange;
        margin-bottom: 15px;

        .panel-heading {
            float: left;
            padding-left: 0;
            padding-bottom: 0;
        }

        .reports {
            width: 279px;
            float: right;
            margin-top: 18px;
            text-align: left;

            .select2-container--bootstrap
            {
                display: inline-block;
                z-index: 9000;
            }

            .reports-title {
                font-weight: bold;
                margin-right: 7px;
                display: inline-block;
            }

            .custom-select {
                display: inline-block;
                width: 150px;
            }

            img {
                height: 26px;
                display: inline-block;
                cursor: pointer;
                margin-left: 3px;
            }
        }
    }

    select {
        display: none !important;
    }

    .custom-select {
        width: 100%;
        position: relative;
        margin-bottom: 5px;

        span {
            display: inline-block;
            width: 100%;
            height: 36px;
            padding: 6px 30px 6px 12px;
            font-size: 14px;
            line-height: 1.6;
            background: $white;
            position: relative;
            cursor: pointer;
            border: 1px solid $border-gray;
            @include border-radius(5px);

            &::before {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid $blue;
                @include transition(all 0.3s ease);
                content: "";
                position: absolute;
                right: 10px;
                top: 15px;
            }

            &.active {
                &::before {
                    @include transform(rotate(180deg));
                }
            }
        }

        ul {
            width: 100%;
            padding: 0;
            background: $white;
            list-style-type: none;
            display: none;
            position: absolute;
            left: 0;
            top: 40px;
            z-index: 1;
            border: 1px solid $border-gray;

            li {
                padding: 6px 12px;
                text-align: left;
                border-bottom: 1px solid $border-gray;
                @include transition(all 0.3s ease);
                cursor: pointer;
                background: $light-gray;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background: $border-gray;
                }
            }
        }
    }

    .header {
        min-height: 68px;
        background-color: $blue;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        z-index: 0;
        padding-top: 4px;

        .app-selection {
            display: inline-flex;
            margin: 15px 0 15px 45px;
            width: 270px;

            @media (max-width: 590px) {
                display: block;
                width: 90%;
                margin: 0 auto 15px;
            }
        }

        .branding {
            padding: 12px 20px;
            display: flex;
            float: left;

             @media (max-width: 590px) {
                float: none;
                justify-content: center;
            }

            i {
                background-image: url('icons/menu.svg');
                height: 23px;
                width: 23px;
                background-repeat: no-repeat;
                cursor: pointer;
                @include transition(all 0.5s ease);
                margin: 7px 20px 0 0px;

                &.small {
                    height: 17px;
                    width: 17px;
                    margin-top: 7px;
                }
            }

            a {
                color: #ffffff;
                height: unset;
                font-size: 17px;
                line-height: 20px;
            }

            .navbar-brand {
                padding: 0;

                img {
                    height: 35px;
                }
            }
        }

        .right-header {
            float: right;

             @media (max-width: 590px) {
                float: none;
             }

            .guest {
                padding: 20px 10px;
            }

            .admin {
                padding: 15px 5px;

                @media (max-width: 590px) {
                    padding: 0 5px 15px;
                    text-align: center;
                }
            }

            a {
                color: $white;
                margin-right: 10px;

                &:first-child {
                    margin-right: 5px;

                    img {
                        height: 35px;
                    }
                }

                img {
                    height: 31px;
                    @include transition(all 0.3s ease);
                    opacity: 0.6;

                    &:hover {
                        @include transform(scale(1.1));
                        opacity: 1.0;
                    }
                }
            }
        }
    }

    #app {
        display: flex;
        flex-direction: row;
        //zoom: 90%;
        background: $white;

        .refresh-social img {
            @include transition(all 0.5s ease);

            &:hover {
                @include transform(rotate(360deg));
            }
        }

        .edit-link {
            color: $black;

            &:hover {
                text-decoration: none;
            }
        }

        .content-container {
            width: 100%;
            margin-top: 22px;

            &#login-content {
                .container {
                    padding-left: 0;

                    .row {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .marginleft200 {
                margin-left: -200px;
            }

            .container {
                width: 100%;
                padding-left: 255px;

                &.closed {
                    padding: 0 15px;
                }

                input[type=search]{
                    border-color: $border-gray;
                    border-width: 1px;
                    border-style: solid;
                    width: 100%;

                    border-radius: 5px;
                    box-shadow: none;

                    &:focus{
                        outline: none;
                        border-color: $blue;
                        border-width: 1px;
                    }
                }

                .col-md-7 {
                    margin: 0 auto;
                    float: none;
                }

                .panel {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;

                    .panel-heading {
                        background-color: transparent;
                        font-size: 30px;
                        text-align: center;
                        border: none;
                        font-weight: bolder;
                    }

                    .panel-body {
                        padding: 0;

                        .preview-phone {
                            background: url(/images/iphone.png);
                            width: 300px;
                            height: 595px;
                            background-repeat: no-repeat;
                            background-size: 100%;
                            position: relative;

                            .arrow {
                                position: absolute;
                                width: 102px;
                                z-index: 100;
                                transform: rotate(18deg);
                                top: -18px;
                                left: 87px;
                            }

                            .preview {
                                top: -40px;
                                left: -16px;
                                position: absolute;
                            }

                            .background {
                                position: absolute;
                                width: 273px;
                                height: 448px;
                                background: white;
                                top: 70px;
                                left: 15px;

                                .advertise {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 72px;
                                    width: 95%;
                                    margin: 5px auto 15px;
                                    color: white;
                                    font-size: 16px;
                                }

                                .action {
                                    width: 100%;
                                    bottom: -46px;
                                    position: relative;
                                }

                                .image {
                                    width: 136.5px;
                                    height: 135px;
                                    margin-bottom: 3px;
                                    margin-right: -2px;
                                    display: inline-flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: white;
                                    font-weight: 500;
                                    font-size: 22px;
                                    vertical-align: top;
                                    text-align: center;
                                    line-height: 25px;
                                    font-style: italic;
                                    word-break: break-word;
                                    padding: 10px;
                                    position: relative;

                                    img {
                                        width: 135px;
                                        position: absolute;
                                        height: 135px;
                                        top: 0;
                                        left: 0;
                                        z-index: 0;
                                    }

                                    div {
                                        width: 135px;
                                        height: 135px;
                                        opacity: 0.5;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 1;
                                    }

                                    span {
                                        position: relative;
                                        z-index: 2;
                                    }
                                }
                            }
                        }

                        .editItem {
                            display: inline-block;
                            color: $black;
                            vertical-align: top;
                        }

                        .button-group {
                            border-top: 1px solid $orange;
                            text-align: right;
                        }

                        span.req {
                            color: red;
                        }

                        .edit,
                        .delete {
                            display: inline-block;
                            margin-right: 5px;

                            img {
                                width: 20px;
                            }
                        }

                        .title {
                            border-bottom: 1px solid $orange;
                            color: $text-color;
                            font-size: 16px;
                            margin: 25px 0;
                            padding: 10px 0;
                            font-weight: bold;
                        }

                        .subtitle {
                            font-size: 14px;
                            color: $text-color;
                        }

                        .on-hover {
                            position: relative;

                            &::before {
                                content: '';
                                position: absolute;
                                background: $black;
                                top: 0;
                                left: 0;
                                @include opacity(0);
                                @include transition(all 0.5s ease);
                                width: 100px;
                                height: 100px;
                                @include border-radius(100%);
                                top: 50%;
                                left: 50%;
                                @include transform(translate(-50%, -50%));
                            }

                            &::after {
                                content: '';
                                background: url(/icons/change-image.png);
                                height: 50px;
                                background-repeat: no-repeat;
                                width: 50px;
                                display: block;
                                position: absolute;
                                @include background-size(100%);
                                top: 50%;
                                left: 50%;
                                @include transform(translate(-50%, -50%));
                                @include opacity(0);
                                @include transition(all 0.5s ease);
                            }

                            &.item-settings {
                                &::after {
                                    background: url(/icons/settings-white.svg);
                                    height: 66px;
                                    background-repeat: no-repeat;
                                    @include background-size(100%);
                                }
                            }

                            &.change-image {
                                &::after {
                                    background: url(/icons/change-image.png);
                                    height: 50px;
                                    background-repeat: no-repeat;
                                    @include background-size(100%);
                                }
                            }

                            &:hover {
                                &::after {
                                    @include opacity(1);
                                }

                                &::before {
                                    @include opacity(0.8);
                                }
                            }
                        }

                        .background-picture {
                            width: 250px;
                            height: 140px;
                            background-color: $white;
                            text-align: center;
                            display: inline-block;
                            cursor: pointer;
                            @include flex();
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            border: 1px solid $light-gray;
                            position: relative;

                            &.hide-hover {
                                &::before,
                                &::after {
                                    display: none;
                                }
                            }

                            &:hover {
                                img {
                                    @include transition(all 0.3s ease);
                                    @include transform(scale(2.5));
                                }
                            }

                            img {
                                width: 35px;
                                @include transition(all 0.3s ease);
                            }
                        }

                        .add-more {
                            cursor: pointer;
                            height: 35px;
                            position: relative;
                            border: 2px solid $orange;
                            background: $orange;
                            width: 100%;

                            &:after {
                                content: "";
                                background-image: url(/images/plus.svg);
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                width: 15px;
                                height: 15px;
                                bottom: 0;
                                margin: auto;
                            }
                        }

                        .go-change-to-athlete {
                            border: 2px solid $blue;
                            background: $blue;
                            cursor: pointer;
                            height: 35px;
                            position: relative;
                            width: 100%;

                            &::after {
                                content: "Activate Athletes";
                                background-image: none;
                                width: 100%;
                                height: 21px;
                                color: $white;
                                text-align: center;
                                font-weight: 500;
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }

                        .bg-image {
                            display: none;
                        }

                        .submit,
                        .remove {
                            display: inline-block;
                            margin-right: 10px;
                        }

                        .btn-danger {
                            background-color: red;
                        }

                        select {
                            width: 100%;
                            background-color: #ffffff;
                            border: none;
                            height: 35px;
                            margin: 10px 0;
                        }

                        input[type=submit] {
                            margin: 10px 0;
                            font-size: 14px;
                            position: relative;
                            border-radius: 0;
                            border: none;
                            background-color: $orange;
                            color: $white;
                            height: 35px;
                            width: 115px;
                        }

                        input[readonly] {
                            background: transparent;
                            padding: 0;
                            margin: 0 !important;
                            box-shadow: none;

                            &:focus {
                                outline: none !important;
                                box-shadow: none !important;
                            }
                        }

                        input[type=text],
                        input[type=password],
                        input[type=number],
                        input[type=search],
                        select {
                            border-color: $border-gray;
                            border-width: 1px;
                            border-style: solid;
                            width: 100%;

                            border-radius: 5px;
                            box-shadow: none;

                            &:focus{
                                outline: none;
                                border-color: $blue;
                                border-width: 1px;
                            }
                        }
                    }
                }
            }
        }
    }

    #datetimepicker,
    #datetimepicker2,
    #datetimepickerto {
        cursor: pointer;
    }

    .inputfile {
        display: none;
    }

    .inputfile + label {
        max-width: 80%;
        font-weight: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        padding: 0.625rem 1.25rem;
    }

    .inputfile:focus + label,
    .inputfile.has-focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }

    .inputfile + label img {
        width: 17px;
        height: 17px;
        vertical-align: middle;
        margin-top: -4px;
        margin-right: 5px;
    }

    .inputfile-1 + label {
        color: $white;
        background-color: $orange;
    }

    .selectedFile {
        display: none;
        width: 250px;
        background: white;
        padding: 10px;
        height: 39px;
        border: 1px solid #eee;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        font-weight: 500;
        margin: 5px 0;
    }

    .optional {
        font-size: 11px;
        margin-top: -8px;
    }

    .st-effect-8 {
        &.st-container {
            -webkit-perspective: 1500px;
            perspective: 1500px;
            -webkit-perspective-origin: 0% 50%;
            perspective-origin: 0% 50%;
        }

        .st-pusher {
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
        }
    }

    .st-container {
        height: 100%;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;

        .st-menu {
            &.closed {
                display: none;
            }
        }

        .st-content {
            width: 100%;
        }

        .st-pusher {
            position: relative;
            left: 0;
            z-index: 99;
            height: 100%;
            width: 100%;
            @include transition(transform 0.5s);

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                content: '';
                opacity: 0;
                @include transition(0.5s, width 0.1s 0.5s, height 0.1s 0.5s);
            }
        }
    }

    table {
        &.dataTable {
            font-size: 12px;
            border: 1px solid $light-gray;

            &.no-footer {
                border-bottom: none;
            }

            thead {
                background: $light-gray;

                th, td {
                    padding: 7px 20px 7px 7px;
                    border-bottom: none;

                    &:last-child {
                        text-align: center;
                    }
                }
            }

            tbody {
                tr {
                    &.odd {
                        background: #f9f9f9;
                    }
                }

                td {
                    padding: 7px;
                    border-right: 1px solid $light-gray;
                    border-bottom: 1px solid $light-gray;

                    &:last-child {
                        text-align: center;
                        border-right: none;
                    }
                }
            }
        }
    }

    .dataTables_wrapper {
        .dataTables_info {
            font-size: 12px;
            padding-top: 15px;
        }
        
        .dataTables_paginate {
            font-size: 12px;
            padding-top: 8px;
        }

        .dataTables_processing {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            margin: 0;
        }
    }
}
