body {
    .polls {
        &.create,
        &.edit {
            .option {
                display: none;
                
                .input-option {
                    position: relative;

                    img {
                        width: 15px;
                        position: absolute;
                        top: 12px;
                        right: 10px;
                        cursor: pointer;
                        @include transition(all 0.5s ease);

                        &:hover {
                            @include transform(rotate(360deg));
                        }
                    }
                }
            }
        }
    }

    .stats-dropdown {
        display: none;
        border: 1px solid $border-gray;
        background: $white;
        margin-top: -10px;
    }

    .question-stats {
        text-align: center;
        padding: 20px;

        .question-donut {
            width: 200px;
            height: 200px;
            display: inline-block;
            vertical-align: middle;

            .novotes {
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                border: 1px solid $orange;
            }
        }

        .answers-title {
            margin: -13px 0px 10px -62px;
            font-weight: 600;
        }

        .answers {
            margin-right: 30px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 40px;

            .answer {
                margin-bottom: 12px;
                text-align: left;

                .answer-0,
                .answer-1,
                .answer-2,
                .answer-3 {
                    position: relative;

                    &::before {
                        position: absolute;
                        content: "";
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                        border-radius: 100%;
                        left: -34px;
                        top: -2px;
                    }
                }

                .answer-0 {
                    &::before {
                        background: $orange;
                    }
                }

                .answer-1 {
                    &::before {
                        background: #b3b3b3;
                    }
                }

                .answer-2 {
                    &::before {
                        background: $dark-black;
                    }
                }

                .answer-3 {
                    &::before {
                        background: $brand-danger;
                    }
                }
            }
        }
    }
}