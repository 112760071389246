body {
    #app {
        .content-container {
            .support {
                .row {
                    .panel {
                        .panel-body {
                            .row-item {
                                width: 100%;
                                display: block;
                                background: $white;
                                position: relative;

                                &.heads {
                                    margin-bottom: 10px;
                                }

                                .cell {
                                    padding: 10px;
                                    display: inline-block;
                                    width: 24%;

                                    &.head {
                                        font-weight: 600;
                                    }
                                }

                                .cross-button {
                                    background: url(/icons/trash.svg);
                                    background-color: transparent;
                                    @include background-size(100%);
                                    width: 20px;
                                    height: 20px;
                                    background-repeat: no-repeat;
                                    position: absolute;
                                    top: 2px;
                                    right: 10px;
                                    @include transition(all 0.5s ease);

                                    &:hover {
                                        @include transform(rotate(360deg));
                                    }
                                }

                                .edit {
                                    position: absolute;
                                    top: 10px;
                                    right: 35px;
                                    @include transition(all 0.5s ease);

                                    &:hover {
                                        @include transform(rotate(360deg));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}